@font-face {
    font-family: "NunitoSans";
    src: url("/assets/fonts/NunitoSans-Regular.ttf");
    font-weight: 200;
    font-style: normal;
}

:root,
:root[mode="ios"],
:root[mode="md"] {
    --ion-font-family: "NunitoSans", sans-serif !important;
    font-family: "NunitoSans", sans-serif !important;
}

ion-split-pane {
    --side-max-width: 250px;
}

ion-header.no-borders::after {
    background: none;
}

ion-header.no-borders ion-toolbar {
    --border-width: 0 !important;
}

ion-modal.ion-modal-scrollable .ion-page {
    overflow: auto;
}

ion-card {
    border-radius: 12px;
}

ion-thumbnail {
    --border-radius: 8px;
}

ion-modal.ion-modal-scrollable .ion-page {
    overflow: auto;
}

ion-modal.welcome-modal {
    --backdrop-opacity: 0.9 !important;
}

ion-popover {
    --width: 320px !important;
}

@media only screen and (min-height: 600px) and (min-width: 768px) {
    ion-modal .modal-wrapper {
        border-radius: 16px !important;
    }
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.link {
    cursor: pointer;
}

.link:hover {
    text-decoration: underline;
}

.ion-divider {
    width: 100%;
    height: 1px;
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: #d7d8da;
}

.ripple-parent {
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.sidebar {
    -webkit-transition: max-width 0.25s cubic-bezier(0.4, 0, 0.2, 1), visibility 0s linear 0s;
    transition: max-width 0.25s cubic-bezier(0.4, 0, 0.2, 1), visibility 0s linear 0s;
}

.show {
    max-width: 280px;
}

.hide {
    max-width: 0px;
}

.content-lg {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.content-md {
    max-width: 992px;
    margin-left: auto;
    margin-right: auto;
}

.content-sm {
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
}

.content-xs {
    max-width: 576px;
    margin-left: auto;
    margin-right: auto;
}

.main-menu .active {
    --background: var(--ion-color-light-tint);
    border-radius: 8px;
}

.main-search {
    padding: 0 !important;
    max-width: 520px !important;
    margin: auto !important;
}

.main-search .searchbar-input {
    text-align: left;
    border-radius: 8px;
    box-shadow: none;
    background-color: var(--ion-color-light-tint);
}


.swiper-pagination {
    bottom: 0 !important;
    cursor: pointer;
    z-index: 1000;
}